module.exports = [{
      plugin: require('/root/analiza-gramaticala-ui/node_modules/gatsby-plugin-glamor/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/root/analiza-gramaticala-ui/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146813885-1"},
    },{
      plugin: require('/root/analiza-gramaticala-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
