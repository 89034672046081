// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-demo-js": () => import("/root/analiza-gramaticala-ui/src/templates/demo.js" /* webpackChunkName: "component---src-templates-demo-js" */),
  "component---src-pages-404-en-js": () => import("/root/analiza-gramaticala-ui/src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("/root/analiza-gramaticala-ui/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analizator-js": () => import("/root/analiza-gramaticala-ui/src/pages/analizator.js" /* webpackChunkName: "component---src-pages-analizator-js" */),
  "component---src-pages-contact-en-js": () => import("/root/analiza-gramaticala-ui/src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("/root/analiza-gramaticala-ui/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exercitii-js": () => import("/root/analiza-gramaticala-ui/src/pages/exercitii.js" /* webpackChunkName: "component---src-pages-exercitii-js" */),
  "component---src-pages-exercitiu-page-js": () => import("/root/analiza-gramaticala-ui/src/pages/ExercitiuPage.js" /* webpackChunkName: "component---src-pages-exercitiu-page-js" */),
  "component---src-pages-forum-js": () => import("/root/analiza-gramaticala-ui/src/pages/forum.js" /* webpackChunkName: "component---src-pages-forum-js" */),
  "component---src-pages-index-en-js": () => import("/root/analiza-gramaticala-ui/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("/root/analiza-gramaticala-ui/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-istoric-js": () => import("/root/analiza-gramaticala-ui/src/pages/istoric.js" /* webpackChunkName: "component---src-pages-istoric-js" */),
  "component---src-pages-lemmatize-js": () => import("/root/analiza-gramaticala-ui/src/pages/lemmatize.js" /* webpackChunkName: "component---src-pages-lemmatize-js" */),
  "component---src-pages-resurse-js": () => import("/root/analiza-gramaticala-ui/src/pages/resurse.js" /* webpackChunkName: "component---src-pages-resurse-js" */)
}

